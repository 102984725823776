import logo from './logo.svg';
import './App.css';
import AutoplayVideo from './autoplayvideo';
import {SlEvent} from 'react-icons/sl'
import {BiHappy,BiTime} from 'react-icons/bi'
import {MdPlace} from 'react-icons/md';
import {CgGames} from 'react-icons/cg'
import bannerImg from './assets/banner.jpg'
function App() {
  return (
    <div className="App">
      <marquee style={{padding:'12px',fontSize:'12px'}}>
        Date will be announced later <BiHappy/>
      </marquee>
      <div className='image-container'>
        <img style={{height:'auto',maxWidth:'100%'}} src={bannerImg} />
        <div className='text-overlay1'>SUNSHINE ESPORTS Tournament</div>
        <div className='text-overlay2'>Calling all gamers and esports enthusiasts!<br/> We are thrilled to announce the ultimate inter-Institute esports tournament!</div>
      </div>
      <div style={{textAlign:'center',border:'#ff0000 1px solid',padding:'12px',marginTop:'12px',width:'80vw'}}>Prize Distribution:
        <div style={{fontSize:'11px'}}>
          <div style={{padding:'2px',margin:'2px'}}>Exciting Prizes: Winner , 1st Runner Teams</div>
          <div style={{padding:'2px',margin:'2px'}}>Participation Prizes : All </div>
          <div style={{padding:'2px',margin:'2px'}}>Registraion Last Date : N/A </div>
        </div>
      </div>
      <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
        <div style={{padding:'12px',borderBottom:'1px',borderBottomColor:'red',borderBottomStyle:'solid'}}>Event Details</div>
       
        <div className='flex-container'>
          <div style={{marginRight:'15px'}}>
            <SlEvent style={{marginRight:'2px'}}/> Date: <span style={{fontSize:'10px'}}>N/A</span>
          </div>
          <div style={{marginLeft:'15px'}}>
            <BiTime style={{marginRight:'2px'}}/> Time: <span style={{fontSize:'10px'}}>N/A</span>
          </div>
        </div>
        <div style={{padding:'12px',borderBottom:'1px',borderBottomColor:'red',borderBottomStyle:'solid'}}>Venue <MdPlace/></div>
        <div style={{textAlign:'center',fontSize:'11px'}}> Online (From the comfort of your home)</div>
        <div style={{padding:'12px',borderBottom:'1px',borderBottomColor:'red',borderBottomStyle:'solid'}}>Games <CgGames/></div>
        <div style={{textAlign:'center',fontSize:'11px'}}> Decide from Voting</div>
        <div style={{padding:'12px',borderBottom:'1px',borderBottomColor:'red',borderBottomStyle:'solid'}}>Tournament Format <CgGames/></div>
        <div style={{margin:'2px',textAlign:'center',fontSize:'11px'}}> Knock-Out based </div>
        <div style={{padding:'12px',borderBottom:'1px',borderBottomColor:'red',borderBottomStyle:'solid'}}>Eligibillity <CgGames/></div>
        <div style={{textAlign:'center',fontSize:'11px'}}>Only Institute Students are allowed</div>
        
      </div>
      <div style={{textAlign:'center',border:'#ff0000 1px solid',padding:'12px',marginTop:'12px',width:'80vw'}}>Registration:
        <div style={{fontSize:'11px'}}>
          <div style={{padding:'2px',margin:'2px'}}>Registraion Fee : 0/-</div>
          <div style={{padding:'2px',margin:'2px'}}>Register Link : <a href='https://1qwtg37mz66.typeform.com/to/W1fYob73' style={{border:'#ccffcc solid 1px',backgroundColor:'cyan',padding:'6px',color:'red'}}>Register Now Click Here !</a></div>
          <div style={{padding:'2px',margin:'2px'}}>Registraion Last Date : N/A </div>
        </div>
      </div>
      <div style={{marginTop:'12px'}}>
      For any inquiries, please contact our organizers
      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',margin:'15px'}}>
        <img style={{borderRadius:'50%',width:'70px'}} src='https://lh3.googleusercontent.com/a/AGNmyxa-P-rh8EWEqGQFGmv6w1vNQ0tF4LYYXXc8ElFVmQ=s83-c-mo'/>
        <img style={{borderRadius:'50%',width:'70px'}} src='https://scontent.fccu25-1.fna.fbcdn.net/v/t39.30808-6/321465577_1710577726005574_2703029956737301060_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=730e14&_nc_ohc=u_lNXHVPB1AAX-Dm0a1&_nc_ht=scontent.fccu25-1.fna&oh=00_AfA2AJpqbODcFpuG9aKgMXFHoLC5OKWWs3XWAdb-X3eqFg&oe=6464E509'/>
      </div>
      </div>
    </div>
  );
}

export default App;
